<template>
  <div class="table-wrapper mt-3">
    <BTable
      :items="getItems"
      :fields="fieldsResult"
      :bordered="true"
      :busy="loading"
      :filter="filter"
      :filter-included-fields="filterOn"
      :class="{ 'row-clicked': rowLink }"
      :empty-text="emptyText ? emptyText : 'Нет элементов'"
      :no-local-sorting="noLocalSorting"
      striped
      hover
      small
      show-empty
      responsive=""
      sticky-header=""
      id="orders-table"
      empty-filtered-text="Нет результатов по данному поиску"
      @row-clicked="rowClick"
      @sort-changed="sortChanged"
    >
      <template #head()="data">
        <div>
          <span
            :title="data.field ? data.field.full : data.label"
            v-b-tooltip.hover
            style="position: relative; z-index: 1"
          >
            {{ data.label }}
          </span>
        </div>
      </template>

      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: getColWidth(field.key) }"
        />
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-4">
          <BSpinner class="align-middle mr-2"></BSpinner>
          <strong>Загрузка...</strong>
        </div>
      </template>

      <template #cell(iterator)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(order_date)="data">
        {{
          (data.item.order ? data.item.order.order_date : data.item.order_date)
            | parseDate
        }}
      </template>

      <template #cell(created_at)="data">
        {{ data.item.created_at | parseDate }}
      </template>

      <template #cell(state)="data">
        <div class="d-flex align-items-center justify-content-center">
          <span
            class="d-flex align-items-center justify-content-center rounded-circle text-white"
            :class="`bg-${stateIcon(data.item.provider_status).color}`"
            style="width: 1.167rem; height: 1.167rem"
            :title="stateIcon(data.item.provider_status).title"
            v-b-tooltip.hover
          >
            <BIcon
              :icon="stateIcon(data.item.provider_status).icon"
              style="width: 1.167rem; height: 1.167rem"
            />
          </span>
        </div>
      </template>

      <template #cell(status)="data">
        <div class="d-flex align-items-center justify-content-center">
          <span
            class="d-flex align-items-center justify-content-center rounded-circle text-white"
            :class="`bg-${stateIcon(data.item.status).color}`"
            style="width: 1.167rem; height: 1.167rem"
            :title="stateIcon(data.item.status).title"
            v-b-tooltip.hover
          >
            <BIcon
              :icon="stateIcon(data.item.status).icon"
              style="width: 1.167rem; height: 1.167rem"
            />
          </span>
        </div>
      </template>

      <template #cell(organization_status)="data">
        <div class="d-flex align-items-center justify-content-center">
          <span
            class="d-flex align-items-center justify-content-center rounded-circle text-white"
            :class="`bg-${stateIcon(data.item.organization_status).color}`"
            style="width: 1.167rem; height: 1.167rem"
            :title="stateIcon(data.item.organization_status).title"
            v-b-tooltip.hover
          >
            <BIcon
              :icon="stateIcon(data.item.organization_status).icon"
              style="width: 1.167rem; height: 1.167rem"
            />
          </span>
        </div>
      </template>

      <template #cell(select)="data">
        <b-form-checkbox
          v-model="selectedRows"
          :value="data.item.id"
          @change="$emit('selected-rows', selectedRows)"
        />
      </template>

      <template #cell(deadline_date)="data">
        {{ data.item.deadline_date | parseDate }}
      </template>

      <template #cell(term)="data">
        {{ data.item.term | parseDate }}
      </template>

      <template #cell(work_start_date)="data">
        {{ data.item.customer.work_start_date | parseDate }}
      </template>

      <template #cell(work_end_date)="data">
        {{ data.item.customer.work_end_date | parseDate }}
      </template>

      <template #cell(date)="data">
        {{ data.item.date | parseDate }}
      </template>

      <template #cell(create-date)="data">
        {{ data.item.created_at | parseDate }}
      </template>

      <template #cell(active-before-date)="data">
        {{ data.item.active_before_at | parseDate }}
      </template>

      <template #cell(comments)="data">
        <div class="d-flex align-items-center">
          <BIcon
            v-if="data.item.customer_comment"
            icon="chat-right-text"
            variant="primary"
            v-b-tooltip.hover.topleft="{
              customClass: 'wide-tooltip',
              title: data.item.customer_comment,
            }"
            class="mr-3"
            style="width: 1.167rem; height: 1.167rem; cursor: pointer"
          />
          <template v-if="isProvider && withCommentModal">
            <div class="d-flex align-items-center justify-content-center">
              <BIcon
                v-if="
                  data.item.provider_comment &&
                  data.item.provider_comment.length > 0
                "
                icon="chat-right-text"
                variant="primary"
                style="width: 1.5rem; height: 1.5rem; cursor: pointer"
                @click="
                  openCommentModal(
                    data.item.id,
                    'comment',
                    data.item.provider_comment
                  )
                "
              />
              <BIcon
                v-else
                icon="plus-square-fill"
                variant="primary"
                style="width: 1.5rem; height: 1.5rem; cursor: pointer"
                @click="openCommentModal(data.item.id, 'comment')"
              />
            </div>
          </template>
          <BIcon
            v-else-if="data.item.provider_comment"
            icon="chat-right-text"
            variant="success"
            v-b-tooltip.hover.topleft="{
              customClass: 'wide-tooltip',
              title: data.item.provider_comment,
            }"
            class="mr-3"
            style="width: 1.167rem; height: 1.167rem; cursor: pointer"
          />
        </div>
      </template>

      <template #cell(object)="data">
        <template v-if="data.field.nesting">
          <template v-if="data.field.nesting === 'customer_object'">
            {{
              data.item.customer_object ? data.item.customer_object.name : ( ( data.item.object && data.item.object.name ) )
            }}
          </template>

          <template v-if="data.field.nesting === 'object_id'">
            {{ data.item.object ? data.item.object.name : "" }}
          </template>
        </template>

        <template v-else>
          {{ getObject(data.item.customer.object_id).name }}
        </template>
      </template>

      <template #cell(subobject)="data">
        {{
          getSubObject(
            data.item.customer.object_id,
            data.item.customer.sub_object_id
          ).name
        }}
      </template>

      <template #cell(work_type)="data">
        <div style="min-width: 11.67rem">
          {{ data.item.customer.work_type }}
        </div>
      </template>

      <template #cell(comment)="data">
        <div v-if="data.item.comment" class="d-flex align-items-center">
          <BIcon
            icon="chat-right-text"
            variant="primary"
            v-b-tooltip.hover.topleft="{
              customClass: 'wide-tooltip',
              title: data.item.comment,
            }"
            class="m-0"
            style="width: 1.167rem; height: 1.167rem; cursor: pointer"
          />
        </div>
      </template>

      <template #cell(organization_comment)="data">
        <div
          v-if="data.item.organization_comment"
          class="d-flex align-items-center"
        >
          <BIcon
            icon="chat-right-text"
            variant="primary"
            v-b-tooltip.hover.topleft="{
              customClass: 'wide-tooltip',
              title: data.item.organization_comment,
            }"
            class="m-0"
            style="width: 1.167rem; height: 1.167rem; cursor: pointer"
          />
        </div>
      </template>

      <template #cell(quantity)="data">
        {{ data.item.quantity | VMask(quantityMask) }}
      </template>

      <template #cell(price)="data">
        {{ data.item.price | VMask(priceMask) }}
      </template>

      <template #cell(file_url)="data">
        <DownloadLink
          v-if="data.item.file_url"
          :id="data.item.id"
          :from="downloadFor"
          :url="data.item.file_url"
        />
      </template>

      <template #cell(file_urls)="data">
        <DownloadLink style="padding-right: 5px;"
            v-for="(file, idx) of data.item.files"
            :key="idx"
            :id="file.id"
            :from="`price-negotiations/${ data.item.id }/files`"
            :url="file.url"
        />
      </template>

      <template #cell(is_confirmed)="data">
        {{ data.item.is_confirmed ? "Да" : "Нет" }}
      </template>

      <template #cell(sub_objects)="data">
        <div class="table-list-ul">
          <ul v-if="data.item.sub_objects && data.item.sub_objects.length">
            <template v-for="object in data.item.sub_objects">
              <li v-if="object.name" :key="object.id">
                {{ object.name }}
              </li>
            </template>
          </ul>
        </div>
      </template>

      <template #cell(contacts)="data">
        <div
          v-if="data.item.contacts && data.item.contacts.length"
          class="table-contacts-list"
        >
          <ul v-for="contact in data.item.contacts" :key="contact.id">
            <li><span>Полное имя:</span> {{ contact.full_name }}</li>
            <li><span>Email:</span> {{ contact.email }}</li>
            <li>
              <span>Телефон:</span> {{ contact.phone | VMask(priceMask) }}
            </li>
          </ul>
        </div>
      </template>

      <template #cell(organization)="data">
        <template v-if="data.field.nesting">
          <template v-if="data.field.nesting === 'organization_id'">
            {{ getOrganization(data.item.organization_id).name }}
          </template>

          <template v-if="data.field.nesting === 'unnested_organization'">
            {{ data.item.organization && data.item.organization.name }}
          </template>
        </template>

        <template v-else>
          {{ getOrganization(data.item.customer.organization_id).name }}
        </template>
      </template>

      <template #cell(contractor)="data">
        <template v-if="data.field.nesting">
          <template v-if="data.field.nesting === 'contractor_contr_agent_id'">
            {{ getContrAgent(data.item.contractor_contr_agent_id).name || data.item.contractor.name }}
          </template>

          <template v-if="data.field.nesting === 'order_contractor'">
            {{ ( data.item.order && data.item.order.contractor_contr_agent_id && getContrAgent(data.item.order.contractor_contr_agent_id).name ) || ( data.item.order && data.item.order.contractor && data.item.order.contractor.contr_agent && data.item.order.contractor.contr_agent.name ) }}
          </template>
        </template>

        <template v-else>
          {{
            data.item.contractor &&
            ( data.item.contractor.contr_agent_id
              ? getContrAgent(data.item.contractor.contr_agent_id).name
              : data.item.contractor.name )
          }}
        </template>
      </template>

      <template #cell(provider)="data">
        <template v-if="data.field.nesting">
          <template v-if="data.field.nesting === 'provider_contr_agent_id'">
            {{
              data.item.provider_contr_agent
                ? data.item.provider_contr_agent.name
                : ( getContrAgent(data.item.provider_contr_agent_id) && getContrAgent(data.item.provider_contr_agent_id).name ) || ( data.item.provider && data.item.provider.name )
            }}
          </template>

          <template v-if="data.field.nesting === 'order_provider'">
            {{ ( data.item && data.item.order && data.item.order.provider_contr_agent_id && getContrAgent(data.item.order.provider_contr_agent_id).name ) || ( data.item.order && data.item.order.provider && data.item.order.provider.contr_agent && data.item.order.provider.contr_agent.name ) }}
          </template>
        </template>

        <template v-else>
          {{
            data.item.provider.contr_agent_id
              ? ( getContrAgent(data.item.provider.contr_agent_id).name || ( data.item.provider.contr_agent && data.item.provider.contr_agent.name ) )
              : data.item.provider.name
          }}
          <!-- {{ data.item.provider.contr_agent.name }} -->
        </template>
      </template>

      <template #cell(work_agreement_number)="data">
        <template v-if="data.field.nesting">
          <template
            v-if="data.field.nesting === 'nested_work_agreement_number'"
          >
            {{
              data.item.work_agreement ? data.item.work_agreement.number : ( data.item.provider_contract && data.item.provider_contract.number || '' )
            }}
          </template>
        </template>

        <template v-else>
          {{
            data.item.customer
              ? getWorkAgreement(data.item.customer.work_agreement_id).number
              : data.item.work_agreement.number
          }}
        </template>
      </template>

      <template #cell(work_agreement_date)="data">
        <template v-if="data.field.nesting">
          <template v-if="data.field.nesting === 'nested_work_agreement_date'">
            {{
              data.item.work_agreement
                ? data.item.work_agreement.date
                : "" | parseDate
            }}
          </template>
        </template>

        <template v-else>
          {{
            data.item.customer
              ? getWorkAgreement(data.item.customer.work_agreement_id).date
              : data.item.work_agreement.date | parseDate
          }}
        </template>
      </template>

      <template #cell(provider_contract_number)="data">
        <template v-if="data.field.nesting">
          <template v-if="data.field.nesting === 'provider_contract_id_number'">
            {{ getProviderContract(data.item.provider_contract_id).number }}
          </template>
        </template>

        <template v-else>
          {{
            data.item.provider
              ? data.item.provider.contract
                ? data.item.provider.contract.number
                : data.item.provider_contract && data.item.provider_contract.number
              : ""
          }}
        </template>
      </template>

      <template #cell(provider_contract_date)="data">
        <template v-if="data.field.nesting">
          <template v-if="data.field.nesting === 'provider_contract_id_date'">
            {{
              getProviderContract(data.item.provider_contract_id).date
                | parseDate
            }}
          </template>
        </template>

        <template v-else>
          {{
            data.item.provider && data.item.provider.contract
              ? data.item.provider.contract.date
              : ( data.item.provider_contract && data.item.provider_contract.date ) | parseDate
          }}
        </template>
      </template>

      <template #cell(contractor_responsible_phone)="data">
        {{ data.item.contractor.contractor_responsible_full_name }}
      </template>

      <template #cell(contractor_responsible_full_name)="data">
        {{
          data.item.contractor.contractor_responsible_phone | VMask(priceMask)
        }}
      </template>

      <template #cell(nomenclature)="data">
        <template v-if="data.field.nesting">
          <template v-if="data.field.nesting === 'nomenclature_name'">
            {{
              data.item.nomenclature
                ? data.item.nomenclature.name
                : data.item.nomenclature_name
            }}
          </template>
        </template>

        <template v-else>
          {{ data.item.nomenclature ? data.item.nomenclature.name : "" }}
        </template>
      </template>

      <template #cell(mnemocode)="data">
        {{ data.item ? ( data.item.nomenclature ? data.item.nomenclature.mnemocode : data.item.mnemocode ) : "" }}
      </template>

      <template #cell(unit)="data">
        {{ getUnit(data.item) }}
      </template>

      <template #cell(payment_register_date)="data">
        {{ data.item.payment_register_date | parseDate }}
      </template>

      <template #cell(consignment_date)="data">
        {{ data.item.consignment_date | parseDate }}
      </template>

      <template #cell(delivery_plan_time)="data">
        {{ data.item.delivery_plan_time | parseDate }}
      </template>

      <template #cell(delivery_fact_time)="data">
        {{ data.item.delivery_fact_time | parseDate }}
      </template>

      <template #cell(delivery_time)="data">
        {{ data.item.delivery_time | parseDate }}
      </template>

      <template #cell(customer_object)="data">
        {{ data.item.customer_object.name }}
      </template>

      <template #cell(customer_sub_object)="data">
        {{
          data.item.customer_sub_object
            ? data.item.customer_sub_object.name
            : null
        }}
      </template>

      <template #cell(sub_object)="data">
        <template v-if="data.field.nesting">
          <template v-if="data.field.nesting === 'customer_sub_object'">
            {{
              data.item.customer_sub_object
                ? data.item.customer_sub_object.name
                : null
            }}
          </template>

          <template v-if="data.field.nesting === 'sub_object_id'">
            {{ data.item.sub_object ? data.item.sub_object.name : null }}
          </template>
        </template>

        <template v-else>
          {{
            data.item.customer_sub_object
              ? data.item.customer_sub_object.name
              : null
          }}
        </template>
      </template>

      <template #cell(organization_id)="data">
        {{ getOrganization(data.item.organization_id).name }}
      </template>

      <template #cell(order)="data">
        {{ data.item.order ? data.item.order.number : "" }}
      </template>

      <template #cell(order_number)="data">
        {{
          data.item.order
            ? data.item.order.number
            : data.item.order_number || ""
        }}
      </template>

      <template #cell(order_number_generated)="data">
        {{
          data.item.order && ( data.item.order.is_generated ? null : data.item.order.number )
        }}
      </template>

      <template #cell(provider_contr_agent_id)="data">
        {{ getContrAgent(data.item.provider_contr_agent_id).name }}
      </template>

      <template #cell(contractor_contr_agent_id)="data">
        {{ getContrAgent(data.item.contractor_contr_agent_id).name }}
      </template>

      <template #cell(customer_object_id)="data">
        {{ getObject(data.item.customer_object_id).name }}
      </template>

      <template #cell(customer_sub_object_id)="data">
        {{
          getSubObject(
            data.item.customer_object_id,
            data.item.customer_sub_object_id
          ).name
        }}
      </template>

      <template #cell(consignment_number)="data">
        <template v-if="data.field.nesting">
          <template v-if="data.field.nesting === 'consignment_number'">
            {{ data.item.number }}
          </template>
        </template>

        <template v-else>
          {{
            data.item.consignment
              ? data.item.consignment.number
              : data.item.number || ""
          }}
        </template>
      </template>

      <template #cell(vat_rate)="data">
        {{ getVatRate(data.item.vat_rate) }}
      </template>

      <template #cell(contract_date)="data">
        {{ data.item.contract_date | parseDate }}
      </template>

      <template #cell(date_fact_delivery)="data">
        {{ data.item.date_fact_delivery | parseDate }}
      </template>

      <template #cell(payment_order_date)="data">
        {{ data.item.payment_order_date | parseDate }}
      </template>

      <template #cell(provider_comment)="data">
        <div
          v-if="data.item.provider_comment"
          class="d-flex align-items-center"
        >
          <BIcon
            icon="chat-right-text"
            variant="primary"
            v-b-tooltip.hover.topleft="{
              customClass: 'wide-tooltip',
              title: data.item.provider_comment,
            }"
            class="m-0"
            style="width: 1.167rem; height: 1.167rem; cursor: pointer"
          />
        </div>
      </template>

      <template #cell(delivery_plan_time)="data">
        <span
          v-if="
            isProvider &&
            canChangeData &&
            canChangeData.includes('delivery_plan_time')
          "
        >
          <b-form-datepicker
            :value="data.item.delivery_plan_time"
            placeholder="Выберите дату"
            locale="ru-RU"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            boundary="viewport"
            @input="changePositionData(data.item.id, data.field.key, $event)"
          />
        </span>
        <span v-else>{{ data.item.delivery_plan_time | parseDate }}</span>
      </template>

      <template #cell(amount_without_vat)="data">
        {{ getPrice( data.item.amount_without_vat ) }}
      </template>

      <template #cell(amount_with_vat)="data">
        {{ getPrice( data.item.amount_with_vat ) }}
      </template>

      <template #cell(type)="data">
        {{ getType(data.item.type) }}
      </template>

      <template #cell(order_customer_object)="data">
        <div v-if="data.item.order && data.item.order.customer && data.item.order.customer.object">
          {{ data.item.order.customer.object.name }}
        </div>
      </template>

      <template #cell(subobject)="data">
        <template v-if="data.item.order && data.item.order.customer">
          {{
            getSubObject(
              data.item.order.customer.object_id,
              data.item.order.customer.sub_object_id
            ).name
          }}
        </template>
      </template>

      <template #cell(order_customer_organization_id)="data">
        <div v-if="data.item.order && data.item.order.customer">
          {{ getOrganization(data.item.order.customer.organization_id).name || ( data.item.order.customer.organization && data.item.order.customer.organization.name ) }}
        </div>
      </template>

      <template #cell(order_provider_contract_number)="data">
        <div v-if="data.item.order">
          {{ data.item.order.contract_number }}
        </div>
      </template>

      <template #cell(work_agreement)="data">
        <div v-if="data.item.order">
          {{ workAgreementsOptions && workAgreementsOptions.length && workAgreementsOptions.find( el => el.value == data.item.work_agreement_id ) && workAgreementsOptions.find( el => el.value == data.item.work_agreement_id ).text }}
        </div>
      </template>

      <template #cell(order_provider_contract_date)="data">
        <div v-if="data.item.order">
          {{ data.item.order.contract_date | parseDate }}
        </div>
      </template>

      <template #cell(unnested_provider_contract)="data">
        <div v-if="data.item.provider_contract">
          {{ data.item.provider_contract.number }}
        </div>
      </template>

      <template #cell(unnested_provider_contract_date)="data">
        <div v-if="data.item.provider_contract">
          {{ data.item.provider_contract.date | parseDate }}
        </div>
      </template>

      <template #cell(unnested_organization)="data">
        {{ data.item.organization && data.item.organization.name }}
      </template>

      <template #cell(contr_agent_comment)="data">
        <div
          v-if="data.item.contr_agent_comment"
          class="d-flex align-items-center"
        >
          <BIcon
            icon="chat-right-text"
            variant="primary"
            v-b-tooltip.hover.topleft="{
              customClass: 'wide-tooltip',
              title: data.item.contr_agent_comment,
            }"
            class="m-0"
            style="width: 1.167rem; height: 1.167rem; cursor: pointer"
          />
        </div>
      </template>

      <template #cell(organization_comment)="data">
        <div
          v-if="data.item.organization_comment"
          class="d-flex align-items-center"
        >
          <BIcon
            icon="chat-right-text"
            variant="primary"
            v-b-tooltip.hover.topleft="{
              customClass: 'wide-tooltip',
              title: data.item.organization_comment,
            }"
            class="m-0"
            style="width: 1.167rem; height: 1.167rem; cursor: pointer"
          />
        </div>
      </template>

      <template #cell(nomenclature_name)="data">
        {{ data.item.nomenclature ? data.item.nomenclature.name : "" }}
      </template>

      <template #cell(nomenclature_unit)="data">
        {{ getNomenclatureUnit(data.item) }}
      </template>

      <template #cell(number)="data">
        <span style="white-space: nowrap;">
          <template v-if="data.field.nesting">
            <template v-if="data.field.nesting === 'id'">
              {{ data.item.id }}
            </template>
          </template>

          <template v-else>
            {{ data.item.number }}
          </template>
        </span>
      </template>

      <template #cell(creation_date)="data">
        {{ moment( data.item.created_at ).format( 'DD.MM.YYYY в HH:mm' ) }}
      </template>

      <template #cell(report-id)="data">
        {{ data.item.id }}
      </template>

      <template #cell(report-price-number)="data">
        {{ data.item.price_negotiation && data.item.price_negotiation.number }}
      </template>

      <template #cell(report-mnemocode)="data">
        {{ data.item.nomenclature && data.item.nomenclature.mnemocode }}
      </template>

      <template #cell(report-name)="data">
        {{ data.item.nomenclature && data.item.nomenclature.name }}
      </template>

      <template #cell(report-unit)="data">
        {{ data.item.nomenclature && data.item.nomenclature.units && data.item.nomenclature.units[ 0 ] && data.item.nomenclature.units[ 0 ].name }}
      </template>

      <template #cell(report-date)="data">
        {{ data.item.price_negotiation &&  moment( data.item.price_negotiation.created_at ).format( 'DD.MM.YYYY в HH:mm' ) }}
      </template>

      <template #cell(report-status)="data">
        {{ data.item.price_negotiation && data.item.price_negotiation.organization_status }}
      </template>

      <template #cell(report-provider)="data">
        {{ data.item.price_negotiation && data.item.price_negotiation.order && data.item.price_negotiation.order.provider && data.item.price_negotiation.order.provider.contr_agent && data.item.price_negotiation.order.provider.contr_agent.name }}
      </template>

      <template #cell(report-days)="data">
        {{
          ( data.item.price_negotiation && data.item.price_negotiation.date_agreement )
            ? data.item.price_negotiation && data.item.price_negotiation.created_at && ( moment( data.item.price_negotiation.date_agreement ).diff( moment( data.item.price_negotiation.created_at ), 'days' ) )
            : data.item.price_negotiation && data.item.price_negotiation.created_at && ( moment().diff( moment( data.item.price_negotiation.created_at ), 'days' ) )
        }}
      </template>

      <template #cell(question_date)="data">
        {{ data.item && data.item.question_date && moment( data.item.question_date ).format( 'DD.MM.YYYY в HH:mm' ) }}
      </template>

      <template #cell(ask_date)="data">
        {{ data.item && data.item.ask_date && moment( data.item.ask_date ).format( 'DD.MM.YYYY в HH:mm' ) }}
      </template>

      <template #cell(ask_text)="data">
        <div class="d-flex m-n1"
            v-if="isAuthor && data && data.item && !data.item.ask_text"
        >
            <div class="p-1 flex-grow-1 w-100">
                <b-form-input
                    v-on:input="onInputAsk( data, $event )"
                    type="text"
                />
            </div>

            <div class="p-1">
                <b-button variant="primary"
                    v-on:click="updateComment( data )"
                >Отправить</b-button>
            </div>
        </div>
        <div
            v-else
        >
            {{ data.item.ask_text }}
        </div>
      </template>

      <template #cell(positions_sum_amount_without_vat)="data">
        {{ getSum( data.item.positions_sum_amount_without_vat ) }}
      </template>

      <template #cell(date-agreement)="data">
        {{ ( data.item.price_negotiation && data.item.price_negotiation.date_agreement ) ? moment( data.item.price_negotiation.date_agreement ).format( 'DD.MM.YYYY' ) : '' }}
      </template>

      <template #cell(current_price_without_vat)="data">
        {{ data.item && data.item.nomenclature && data.item.nomenclature.price }}
      </template>
    </BTable>

    <div v-if="withTotal" class="pt-2">
      <template v-if="Array.isArray(withTotal)">
        <span v-for="(total, index) in getTotal()" :key="index">
          {{ total.title }}:
          <strong>{{ total.value | VMask(priceMask) }} руб.</strong>
        </span>
      </template>
      <template v-else>
        Всего сумма по документу:
        <strong>{{ getTotal() | VMask(priceMask) }} руб.</strong>
      </template>
    </div>

    <div v-if="withSpecifiedTotal" class="pt-2" v-html="withSpecifiedTotal" />

    <div v-if="pagination" class="d-flex mt-4 align-items-center">
      <BPagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        first-number
        aria-controls="orders-table"
        class="flex-grow-1 mr-3 mb-0"
        :disabled="loading"
        @change="paginationChange"
      />
      <div class="d-flex align-items-center">
        <span class="text-muted mr-3" style="white-space: nowrap"
          >На странице</span
        >
        <b-form-select
          v-model="perPage"
          :options="perPages"
          class="ml-3"
          disabled-field="notEnabled"
          :disabled="loading"
          @change="$emit('per-page-change', $event)"
        />
      </div>
    </div>

    <b-modal
      v-if="withCommentModal"
      id="edit-position-comment"
      title="Добавить комментарий"
      centered
    >
      <b-form-textarea
        id="textarea"
        v-model.trim="changeProviderComment.value"
        placeholder="Текст комментария"
      />

      <template #modal-footer="{ hide }">
        <b-button variant="outline-primary" @click="hide()"> Отмена </b-button>
        <b-button variant="primary" @click="saveComment"> Добавить </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import global from "@/utils/variables";
import DownloadLink from "@/components/UI/DownloadLink";
import moment from 'moment'
import axios from 'axios'

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: " ",
});

const quantityMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: ",",
});

export default {
  name: "DefaultTable",
  components: {
    DownloadLink,
  },
  props: {
    headers: Array,
    data: [Object, Array],
    fields: Array,
    filter: {
      type: [String, Number],
      default: null
    },
    loading: Boolean,
    rowLink: String,
    emptyText: String,
    withTotal: [String, Array],
    withSpecifiedTotal: String,
    excludeColumns: {
      type: Array,
      default() {
        return [];
      },
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    withIterator: {
      type: Boolean,
      default: false,
    },
    selectedColumns: Array,
    withCommentModal: Boolean,
    canChangeData: Array,
    downloadFor: String,
    noLocalSorting: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedColumns(newVal) {
      this.selectedRows = newVal;
    },
  },
  computed: {
    ...mapGetters([
      "getOrganization",
      "getSubObject",
      "getWorkAgreement",
      "getObject",
      "getProviderContract",
      "getContrAgent",
      "getVatRate",
      "getItems"
    ]),
    ...mapGetters( {
      workAgreementsOptions: 'getWorkAgreementsOptions'
    } ),
    getItems() {
      return Array.isArray(this.data) ? this.data : this.data.data;
    },
    isProvider() {
      return this.$store.getters.isProvider;
    },
    rows() {
      return this.data.total;
    },
    fieldsResult() {
      let res = [];
      if (this.fields) {
        res = this.fields.filter(
          (item) => this.excludeColumns.indexOf(item.key) === -1
        );
      }
      if (this.withIterator) {
        res.unshift({
          key: "iterator",
          label: "№",
          sortable: false,
        });
      }
      return res;
    },
    isAuthor() {
        return this.$store.state?.priceNegotiations?.currentNegotiation?.creator_contr_agent_id == this.$store.state?.account?.user?.contr_agent_id
    }
  },
  data: () => ({
    apiUrl: global.API_URL,
    perPage: 25,
    perPages: [10, 15, 25, 50],
    currentPage: 1,
    filterOn: [],
    selectedRows: [],
    priceMask: currencyMask,
    quantityMask,
    changeProviderComment: {
      id: null,
      key: null,
      value: null,
    },
    moment
  }),
  methods: {
    getLabel(data) {
      return this.fieldsResult.filter((item) => item.key === data.field.key)[0];
    },
    rowClick(item) {
      if (this.rowLink) {
        this.$router.push(`${this.rowLink}/${item.id}`);
      }
    },
    stateIcon(title) {
      switch (title) {
        case "Согласовано":
          return {
            color: "success",
            title: "Согласовано",
            icon: "check",
          };
        case "На рассмотрении":
          return {
            color: "warning",
            title: "На рассмотрении",
            icon: "exclamation",
          };
        case "Черновик":
          return {
            color: "secondary",
            title: "Черновик",
            icon: "exclamation",
          };
        case "Отклонено":
          return {
            color: "danger",
            title: "Отклонено",
            icon: "x",
          };
        case "Не согласовано":
          return {
            color: "danger",
            title: "Не согласовано",
            icon: "x",
          };
        case "Закрыт":
          return {
            color: "secondary",
            title: "Закрыт",
            icon: "x",
          };
        default:
          return {
            color: "secondary",
            title: "Неизвестно",
            icon: "exclamation",
          };
      }
    },
    getTotal() {
      if (Array.isArray(this.withTotal)) {
        let resArr = [];
        this.withTotal.forEach((item) => {
          const resPrice = this.data?.data?.reduce((prev, curr) => {
            return Number(prev) + Number(curr[item.value]);
          }, 0);
          resArr.push({
            title: item.title,
            value: resPrice,
          });
        });
        return resArr;
      } else {
        if (this.withTotal.trim().length > 0) {
          return this.data?.data?.reduce((prev, curr) => {
            return Number(prev) + Number(curr[this.withTotal]);
          }, 0);
        } else {
          return "Не указано";
        }
      }
    },
    getColWidth(key) {
      const sizes = {
        iterator: "25px",
        select: "25px",
        id: "25px",
        number: "25px",
        state: "25px",
        status: "25px",
        mnemocode: "110px",
        unit: "190px",
        count: "65px",
        price: "70px",
        sum: "85px",
        term: "75px",
        comment: "75px",
        address: "190px",
        comments: "50px",
        amount_without_vat: "85px",
        vat_rate: "85px",
        amount_with_vat: "85px",
        organization_comment: "50px",
        organization_status: "30px"
      };
      return sizes[key] || "auto";
    },
    getUnit(item) {
      if (item.units && item.units[0]) {
        return item.units[0].name;
      }
      if (item.nomenclature && item.nomenclature.units) {
        return item.nomenclature.units[0]
          ? item.nomenclature.units[0].name
          : "";
      }
    },
    async changePositionData(id, key, value) {
      const orderId = this.$route.params.id;
      await this.$store
        .dispatch("updatePosition", { orderId, id, key, value })
        .then(() => {
          this.$emit("refresh-table");
          this.$bvModal.hide("edit-position-comment");
        })
        .catch(() => {
          this.$bvToast.toast("Что-то пошло не так, попробуйте позднее", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
        });
      this.$emit("refresh-table");
    },
    openCommentModal(id, key, value) {
      this.changeProviderComment.id = id;
      this.changeProviderComment.key = key;
      this.changeProviderComment.value = value;
      this.$bvModal.show("edit-position-comment");
    },
    async saveComment() {
      await this.changePositionData(
        this.changeProviderComment.id,
        this.changeProviderComment.key,
        this.changeProviderComment.value
      );
    },
    getYear(date) {
      if (date) {
        return date.split("-")[0];
      }
    },
    getAmountWithoutVat(count, price) {
      const res = count * price;
      return isNaN(res) ? "" : res === 0 ? "" : res;
    },
    getAmountWithVat(count, price, vat) {
      const res = count * price * vat;
      return isNaN(res) ? "" : res === 0 ? "" : res;
    },
    getType(type) {
      const types = {
        contract_work: "Подрядные работы",
        contract_home_method: "Договор по хоз. способу",
      };
      return types[type] || "Не указано";
    },
    sortChanged(e) {
      if (this.noLocalSorting) {
        this.$emit("sort-change", e);
      }
    },
    paginationChange(event) {
      this.$emit("pagination-change", event);
    },
    getNomenclatureUnit(item) {
      return item.type === "change"
        ? item.nomenclature?.units?.[0]?.name
        : item.nomenclature_unit;
    },
    onInputAsk( comment, value ) {
      comment.item.ask_text_value = value
      // this.commentsAsks[ comment.item.id ] = value
    },

    async updateComment( data ) {
      const ask = data.item.ask_text_value
      try {
          const response = await axios.post(
              `price-negotiations/${ data.item.price_negotiation_id }/comments/${ data.item.id }/ask`,
              {
                  text: ask
              }
          )
          data.item.ask_text = response.data.ask_text
          data.item.ask_date = response.data.ask_date
      } catch( error ) {
          error
      }
    },
    getPrice( data ) {
      try {
        return ( +data ).toFixed( 2 );
      } catch( error ) {
        return data;
      }
    },
    getSum( data ) {
      try {
        return ( +data ).toFixed( 3 );
      } catch( error ) {
        return data;
      }
    }
  },
};
</script>
